import { useState, useEffect } from "react";
import { styled } from "styled-components";

import actions from "src/client/actions";
import { Button, Container } from "src/client/styles/layout";
import Input from "src/client/components/Inputs/official";
import InputPhone from "src/client/components/Inputs/telephone";

import BackgroundPattern from "src/client/assets/images/background-pattern.png";
import { GA_SEND } from "src/client/analytics";
import { LeadValidation } from "src/core/helpers/validations/lead";
import useRouter from "src/client/hooks/useRouter";
import { useLocation } from "react-router-dom";

const SectionWrapper = styled.section`
    padding: 120px 0;
    background-image: url(${BackgroundPattern});
    background-size: cover;
    background-position: center;
    background-color: var(--color-primary-light);
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 576px) {
        padding: 20px 0;
    }
`;

const FormWrapper = styled.div`
    max-width: 640px;
    margin: 0 auto;
    text-align: center;
    color: #0a8172;
    padding: 50px 40px 40px;
    background: #f3f3f3;
    border-radius: 20px;
  
    @media (max-width: 576px) {
      	padding: 10px 10px 40px;
		margin: 0 -10px;
    }
`;

const Title = styled.h2`
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 10px;

    @media (max-width: 576px) {
		padding: 20px;
		margin: 0 auto;
		max-width: 300px;
    }
`;

const Subtitle = styled.p`
    font-size: 1.4rem;
    margin-bottom: 30px;
    margin: 0 auto 40px;

    @media (max-width: 576px) {
      	max-width: 300px;
    }
`;

const StyledForm = styled.form`

    .textarea ~ ._label {
        background-color: var(--color-white);
    }

    h3 {
      margin: 20px 0 30px;
      font-weight: 700;
      font-size: 1.2rem;
    }

    Button {
      margin: 40px 0 0;
      max-width: 100%;
      width: 100%;
    }

    @media (max-width: 576px) {
      Button {
        padding: 20px 10px;
        font-size: 1.2rem;
      }
    }
`;

export default function Form7Days() {

  var [form, setForm] = useState({}) as any;
  const [errors, setErrors] = useState({}) as any;
  const [loading, setLoading] = useState(false) as any;

  const router = useRouter();
  const location = useLocation();
  
  async function handleSubmit(ev: any) {
    ev.preventDefault();

    if (loading) return;
    setLoading(true);

    try {
      form.message = "[FORMULARIO] Teste na sua casa"
      LeadValidation(form);

      await actions.api.post("/leads", form).then(response => {
        if (!response.data.success) throw response.data;
				
        var examesPage = location.pathname.startsWith("/exames");
				GA_SEND(`envio_formulario_${examesPage ? 'exames' : 'aparelhos'}`);
				router(`/obrigado_${examesPage ? 'exames' : 'aparelhos'}`);
      });
    } catch (e: any) {
      setErrors(e.response?.data?.message || e);
      setLoading(false);
    }
  }

  useEffect(() => {
    setErrors({});
  }, [form]);

  return (
    <SectionWrapper>
      <Container>
        <FormWrapper>
          <Title>Teste na sua casa</Title>
          <Subtitle>Preencha os campos abaixo e entraremos em contato!</Subtitle>
          <StyledForm onSubmit={handleSubmit}>
            
            <div className="fields">
              <Input 
                error={errors?.name} 
                type="text" 
                placeholder="Nome completo *" 
                textTransform="capitalize"
                onChange={(e: any) => setForm({ ...form, name: e.target.value })} 
              />
              <Input 
                error={errors?.email} 
                type="email" 
                placeholder="E-mail *"
                textTransform="lowercase"
                onChange={(e: any) => setForm({ ...form, email: e.target.value })} 
              />
              <InputPhone 
                error={errors?.phone} 
                onChange={(phone: any) => setForm({ ...form, phone })} 
              />
            </div>
            
            <Button loading={loading ? true : undefined}>Enviar</Button>
          </StyledForm>
        </FormWrapper>
      </Container>
    </SectionWrapper>
  );
}